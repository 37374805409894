<template>
  <div class="content-div">
    <div class="tr p20">
      <el-button class="filter-item" style="margin-left: 10px;" type="primary" icon="el-icon-plus" 
        @click="addDept">
        新增
      </el-button>
    </div>
    <el-table v-loading="deptLoading" :data="deptData" class="w-100" element-loading-text="Loading..." border fit
      highlight-current-row row-key="id">
      <el-table-column align="center" label="部门ID" width="120" prop="id"></el-table-column>
      <el-table-column label="部门名称" prop="name"></el-table-column>
      <el-table-column label="上级部门" width="110" align="center" prop="parentName">
        <template slot-scope="scope">
          <span>
            {{ scope.row.data.data.parentName }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="80" align="center">
        <template slot-scope="scope">
          <el-tag :type="scope.row.data.data.enabledStatus === 0 ? 'success' : 'danger'">
            {{ scope.row.data.data.enabledStatus === 0 ? '正常' : '停用' }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="排序号" width="110" align="center" prop="orderNum">
        <template slot-scope="scope">
          <span>
            {{ scope.row.data.data.orderNum }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="280" align="center">
        <template slot-scope="scope">
          <el-button-group>
            <el-button type="primary" size="small" icon="el-icon-edit" @click="handleEdit(scope.row.data.data)" >修改
            </el-button>
            <el-button type="danger" size="small" icon="el-icon-delete" @click="handleDelete(scope.row.id)" v-if="scope.row.parentId!=='null'">删除
            </el-button>
            <!-- <el-button :type="scope.row.data.data.enabledStatus === 0 ? 'warning' : 'success'" size="small" icon="el-icon-minus"
              @click="handleStopUsing(scope.row.data.data)" v-if="$checkPermissionForAsync(['sys:dept:update'])">
              {{ scope.row.data.data.enabledStatus === 0 ? '停用' : '启用' }}
            </el-button> -->
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <!--  新增、修改弹出框  -->
    <el-dialog :title="dialogTitle" :visible.sync="editDialog" width="30%" :close-on-click-modal="false" @opened="handleDialogOpen">
      <el-form ref="form" :model="currentDept" label-width="120px" :rules="deptRules">
        <el-form-item label="部门名称" prop="name">
          <el-input v-model="currentDept.name"></el-input>
        </el-form-item>
        <el-form-item label="上级部门" prop="activeDept" v-if="!currentDept||(currentDept.parentId!==null&&currentDept.parentId!==0)">
          <el-cascader @change="handleChange" v-model="activeDept" :options="deptDataArr" :props="{value: 'id', label: 'name', children: 'children', checkStrictly: true}"></el-cascader>
        </el-form-item>
        <el-form-item label="排序号" prop="orderNum">
          <el-input-number v-model="currentDept.orderNum"></el-input-number>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog_footer">
        <el-button @click="editDialog = false">取 消</el-button>
        <el-button type="primary" @click="onDeptSubmit" :loading="editButtonLoading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        //部门管理
        deptData: [],
        deptLoading: false,
        editDialog: false,
        addDeptDialog: false,
        addDeptForm: {},
        activeDept: [],
        dialogTitle: '',
        currentDept: {},
        deptRules: {
          name: [{
            required: true,
            message: '请输入部门名称',
            trigger: 'blur'
          }],
          orderNum: [{
            required: true,
            message: '请选择或输入排序号',
            trigger: 'blur'
          }]
        },
        editButtonLoading: false,
        deptDataArr:[]
      }
    },
    created() {
      // this.loadDeptData()
    },
    methods: {
      //部门管理
      async loadDeptData() {
        this.deptLoading = true;
        let res = await this.$post(`sys/dept/tree`, {}, "", "", true);
        if (res) {
          this.deleteNullNode(res);
          this.dept = this.deptData = res;
          this.deptLoading = false;
        }
      },
      addDept() {
        this.dialogTitle = '新增';
        // this.deptDataArr=JSON.parse(JSON.stringify(this.dept))
        this.currentDept = {
          departSubsidiaryCorporation: {},
          departProjectDepartment: {},
          departAgent: {},
          departServiceCenter: {},
          type: '',
          orderNum:1
        };
        this.activeDept = [];
        this.editDialog = true;
      },

      deleteNullNode(nodes) {
        for (let node of nodes) {
          if (node.children != null && node.children.length > 0) {
            this.deleteNullNode(node.children)
          } else {
            delete node.children
          }
        }
      },
      dealDeptData(data){
        data.forEach((v)=>{
          if(v.id==this.currentDept.deptId){
            let index=data.indexOf(v)
            data.splice(index,1)
          }else{
            if(v.children){
              this.dealDeptData(v.children)
            }
          }
        })
      },
      handleEdit(row) {
        this.dialogTitle = '修改';
        this.currentDept = Object.assign({}, row);
        this.deptDataArr=JSON.parse(JSON.stringify(this.dept))
        this.dealDeptData(this.deptDataArr)
        this.editDialog = true;
      },
      handleDialogOpen: function() {
        setTimeout(() => {
          switch (this.currentDept.type) {
            case "1":
              this.$refs.subsidiary.setFormData(this.currentDept.departSubsidiaryCorporation);
              break;
            case "2":
              this.$refs.project.setFormData(this.currentDept.departProjectDepartment);
              break;
            case "3":
              this.$refs.agent.setFormData(this.currentDept.departAgent);
              break;
            case "4":
              this.currentDept.departServiceCenter = Object.assign({}, this.currentDept.departServiceCenter);
              this.$refs.service.setFormData(this.currentDept.departServiceCenter);
              break;
          }

          this.activeDept = this.findDeptIdPath(this.currentDept.parentId, '', this.deptData).split(',').slice(1)
            .reverse();
        }, 300)
      },
      handleDelete(id) {
        this.$confirm('确定删除选中的记录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          let res = await this.$post('sys/dept/delete', {
            deptId: Number(id)
          }, "", "form", true);
          if (res.code === 0) {
            this.$message.success('删除成功')
            this.loadDeptData();
          } else {
            this.$message.error('删除失败')
          }
        })
      },
      handleStopUsing(row) {
        let url, message;
        if (row.enabledStatus === 0) {
          url = `sys/dept/stop/using/${row.deptId}`;
          message = '停用';
        } else {
          url = `sys/dept/enable/${row.deptId}`;
          message = '启用';
        }
        this.$confirm(`${message}该机构同时也${message}该机构下面的所有子机构，确定${message}该机构?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          this.tabLoading = true;
          let res = await this.$get(url, {}, "", true);
          if (res.code == 0) {
            this.$message.success('操作成功')
            this.loadDeptData()
          }
        })
      },
      onDeptSubmit() {
        const saveOrUpdateDept = async () => {
          if (this.dialogTitle === '新增') {
            //部门选择最后一个
            for (let i = this.activeDept.length - 1; i >= 0; i--) {
              if (this.activeDept[i]) {
                this.currentDept.parentId = this.activeDept[i];
                break
              }
            }
            if(!this.currentDept.parentId){
              this.$message({
                        message: '请选择上级部门',
                        type: 'warning'
                      });
                      return
            }
            this.editButtonLoading = true;
            let res = await this.$post('sys/dept/save', this.currentDept, "", "", true);
            if (res.code === 0) {
              this.$message.success('保存成功')
              this.loadDeptData()
              this.editButtonLoading = false;
              this.editDialog = false;
            } else {
              this.editButtonLoading = false;
            }
          } else {
            if(this.currentDept.parentId !== null && this.currentDept.parentId !== 0){
              //部门选择最后一个
              for (let i = this.activeDept.length - 1; i >= 0; i--) {
                if (this.activeDept[i]) {
                  this.currentDept.parentId = this.activeDept[i];
                  break
                }
              }
              if(!this.currentDept.parentId){
                this.$message({
                          message: '请选择上级部门',
                          type: 'warning'
                        });
                        return
              }
            }

            this.editButtonLoading = true;
            this.updateDept()
          }
        }

        this.$refs.form.validate((valid) => {
              if (valid) {
                saveOrUpdateDept();
              } else {
                console.log('error submit!!');
                return false;
              }
            });
    
      this.editButtonLoading = false;
      },

      async updateDept() {
        console.log(this.activeDept)
        if(this.currentDept.parentId!==0&&this.currentDept.parentId!==null){
          let res = await this.$post(`sys/dept/checkParentDepartId/${this.currentDept.deptId}/${this.currentDept.parentId}`, {},
              "", "", true);
          if (res.code == 0) {
            let re = await this.$post('sys/dept/update', this.currentDept, "", "", true);
            if (re && re.code == 0) {
              this.$message.success('保存成功')
              this.loadDeptData()
              this.editDialog = false;
            }
          } else {
            this.editButtonLoading = false;
          }
        }else{
          let re = await this.$post('sys/dept/update', this.currentDept, "", "", true);
          if (re && re.code == 0) {
            this.$message.success('保存成功')
            this.loadDeptData()
            this.editDialog = false;
          }
        }

      },

      findDeptIdPath(id, idPath, data) {
        for (let item of data) {
          if (item.id === id + '') {
            return idPath + ',' + item.id;
          }
          if (item.children) {
            idPath = this.findDeptIdPath(id, idPath, item.children);
          }
          if (idPath !== '') {
            return idPath += ',' + item.id;
          }
        }

        if (idPath == null) {
          return '';
        } else {
          return idPath;
        }
      },
      handleChange(val) {
        this.currentDept.type = null;
        if (val) {
          this.currentDept.type = val;
        }
      },
      getTxt(e) {
        this.noticeForm.content = e;
      }

    }
  }
</script>

<style>
  .content-div {
    padding: 2vh;
     box-sizing: border-box;
  }

  .p20 {
    padding: 20px;
  }
</style>
